<template>
  <div class="w-100 h-100 p-md">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div class="text-left titleClass p-sm b-r-5 font-xl">东莞操作部——昨天入库订单（昨天9:00~今天9:00）</div>
            <div class="text-left m-t-sm font-bold font-md">东莞操作部</div>
            <div class="m-t-md">
              <el-row>
                <el-col :span="18">
                  <div class="grid-content bg-purple">
                    <ve-funnel :data="DongGuanOperationOrder" :settings="funnelExtend" height="300px" style="max-width: 600px;margin: 0 auto;"></ve-funnel>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div>
                    分拣机分拣订单
                  </div>
                  <div class="titleClass p-sm b-r-5 m-t-sm font-lg">
                    {{ sortCountInDongguan }}单
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div class="text-left titleClass p-sm b-r-5 font-xl">巴西退件仓——昨天入库订单（巴西时间昨天0:00~24:00）</div>
            <div class="text-left m-t-sm font-bold font-md">巴西退件仓操作部</div>
            <div class="m-t-md">
              <el-row>
                <el-col :span="24">
                  <div class="grid-content bg-purple">
                    <ve-funnel v-if="hasData > 0" :data="BaXiOperationOrder" :settings="funnelExtend" height="300px" style="max-width: 600px;margin: 0 auto;"></ve-funnel>
                    <div v-else style="line-height: 100px;height: 300px;">暂无数据</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="24" class="m-t-md">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div class="text-left titleClass p-sm b-r-5 font-xl">东莞仓储部——昨天工作情况（昨天0:00~24:00）</div>
            <el-row :gutter="20">
              <el-col :span="6" class="p-md">
                <div class="grid-content bg-purple">
                  <div class="text-center m-b-md m-t-md font-bold font-md">当日下单数量</div>
                  <div class="titleClass p-sm b-r-5 m-t-sm font-lg m-auto" style="width: 160px;">
                    {{ storage.Warehousing }}
                  </div>
                </div>
              </el-col>
              <el-col :span="6" class="p-md">
                <div class="grid-content bg-purple">
                  <div class="grid-content bg-purple">
                    <div class="text-center m-b-md m-t-md font-bold font-md">待处理包裹</div>
                    <div class="titleClass p-sm b-r-5 m-t-sm font-lg m-auto" style="width: 160px;">
                      {{ storage.Pending }}
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6" class="p-md">
                <div class="grid-content bg-purple">
                  <div class="grid-content bg-purple">
                    <div class="text-center m-b-md m-t-md font-bold font-md">处理中包裹</div>
                    <div class="titleClass p-sm b-r-5 m-t-sm font-lg m-auto" style="width: 160px;">
                      {{ storage.Processing }}
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6" class="p-md">
                <div class="grid-content bg-purple">
                  <div class="grid-content bg-purple">
                    <div class="text-center m-b-md m-t-md font-bold font-md">当日出库包裹</div>
                    <div class="titleClass p-sm b-r-5 m-t-sm font-lg m-auto" style="width: 160px;">
                      {{ storage.ExWarehouse }}
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </el-col>
      <el-col :span="24" class="m-t-md">
        <div class="grid-content bg-purple">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-card class="box-card">
                <div>
                  <div class="text-left titleClass p-sm b-r-5 font-xl">智利妥投率与时效——{{ date }}入库订单</div>
                  <div class="m-t-md">
                    <el-row>
                      <el-col :span="10">
                        <div class="grid-content bg-purple">
                          <div class="text-center m-b-md font-bold font-md">7天妥投率</div>
                          <ve-pie :data="clProportionIncome7" :extend="ringExtend" :key="newKey" height="220px" />
                        </div>
                      </el-col>
                      <el-col :span="10">
                        <div class="grid-content bg-purple">
                          <div class="text-center m-b-md font-bold font-md">15天妥投率</div>
                          <ve-pie :data="clProportionIncome15" :extend="ringExtend" :key="newKey" height="220px" />
                        </div>
                      </el-col>
                      <el-col :span="4">
                        <div>
                          平均妥投时效
                        </div>
                        <div class="titleClass p-sm b-r-5 m-t-sm font-lg m-auto" style="max-width: 160px;">
                          {{ clAverageRate }}天
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <div>
                  <div class="text-left titleClass p-sm b-r-5 font-xl">巴西妥投率与时效——{{ date }}入库订单</div>
                  <div class="m-t-md">
                    <el-row>
                      <el-col :span="10">
                        <div class="grid-content bg-purple">
                          <div class="text-center m-b-md font-bold font-md">7天妥投率</div>
                          <ve-pie :data="brProportionIncome7" :extend="ringExtend" :key="newKey" height="220px" />
                        </div>
                      </el-col>
                      <el-col :span="10">
                        <div class="grid-content bg-purple">
                          <div class="text-center m-b-md font-bold font-md">15天妥投率</div>
                          <ve-pie :data="brProportionIncome15" :extend="ringExtend" :key="newKey" height="220px" />
                        </div>
                      </el-col>
                      <el-col :span="4">
                        <div>
                          平均妥投时效
                        </div>
                        <div class="titleClass p-sm b-r-5 m-t-sm font-lg m-auto" style="max-width: 160px;">
                          {{ brAverageRate }}天
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import veFunnel from 'v-charts/lib/funnel.common'
import VePie from 'v-charts/lib/pie.common';
import {
  companyBusinessStatistical
} from '@/api/queryData';
export default {
  name: 'index',
  components: {
    veFunnel,
    VePie
  },
  data() {
    return {
      DongGuanOperationOrder: {
        columns: ['类型', '数值'],
        rows: []
      },
      BaXiOperationOrder: {
        columns: ['类型', '数值'],
        rows: []
      },
      brProportionIncome7: {
        columns: ['状态', '数值'],
        rows: []
      },
      brProportionIncome15: {
        columns: ['状态', '数值'],
        rows: []
      },
      clProportionIncome7: {
        columns: ['状态', '数值'],
        rows: []
      },
      clProportionIncome15: {
        columns: ['状态', '数值'],
        rows: []
      },
      funnelExtend: {
        label: {
          formatter: '{b}：{c}',
          position: 'inside',
          color: '#000'
        }
      },
      ringExtend: {
        label: {
          formatter: '{b}:\n{c}%',
          color: '#000',
          fontSize: 12
        },
        legend: {
          show: true
        },
        series: {
          radius: '50%',
          center: ['50%', '55%'],
          labelLine: {
            length: 10,
            length2: 5
          },
          avoidLabelOverlap: true
        }
      },
      sortCountInDongguan: '',
      storage: {
        Warehousing: '',
        Pending: '',
        Processing: '',
        ExWarehouse: ''
      },
      date: '',
      brAverageRate: 0,
      clAverageRate: 0,
      hasData: 0,
      newKey: new Date().getTime()
    }
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取当前日期前后的日期
    getDay(num, str) {
      const today = new Date();
      const nowTime = today.getTime();
      const ms = 24 * 3600 * 1000 * num;
      today.setTime(parseInt(nowTime + ms));
      const oYear = today.getFullYear();
      let oMoth = (today.getMonth() + 1).toString();
      if (oMoth.length <= 1) oMoth = '0' + oMoth;
      let oDay = today.getDate().toString();
      if (oDay.length <= 1) oDay = '0' + oDay;
      return oYear + str + oMoth + str + oDay;
    },
    getData() {
      companyBusinessStatistical().then(res => {
        if (res.code === 200) {
          const date = this.getDay(-16, '-').split('-');
          const dateNew = this.getDay(-16, '-');
          this.date = date[1] + '月' + date[2] + '日';
          this.DongGuanOperationOrder.rows = [
            { '类型': '订单入库', '数值': res.data.receipt_count_in_dongguan_operate },
            { '类型': '大包装载', '数值': res.data.pkg_count_in_dongguan_operate },
            { '类型': '总单配载', '数值': res.data.fit_count_in_dongguan_operate }
          ];
          this.sortCountInDongguan = res.data.sort_count_dongguan_operate;
          this.hasData = res.data.receipt_count_in_brazil_warehouse;
          this.BaXiOperationOrder.rows = [
            { '类型': '订单入库', '数值': res.data.receipt_count_in_brazil_warehouse }
          ];
          this.storage.Warehousing = res.data.receipt_count_in_dongguan_warehouse;
          this.storage.Pending = res.data.pending_count_in_dongguan_warehouse;
          this.storage.Processing = res.data.packaged_count_in_dongguan_warehouse;
          this.storage.ExWarehouse = res.data.over_fit_count_in_dongguan_warehouse;
          // 计算两个日期之间的天数
          const getDaysBetween = (dateString1, dateString2) => {
            const startDate = Date.parse(dateString1);
            const endDate = Date.parse(dateString2);
            if (startDate > endDate){
              return 0;
            }
            if (startDate === endDate){
              return 1;
            }
            const days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
            return  days.toFixed(0);
          }
          /********* 巴西 *************/
          const br_delivery_count_statistical_info = JSON.parse(res.data.br_delivery_count_statistical_info);
          const brDataArr = [];
          let brCountAll = 0;
          br_delivery_count_statistical_info.map(item => {
            brCountAll = brCountAll + item.count;
            if (item.delivered_time) {
              brDataArr.push(item);
            }
          });
          // 按日期排序
          if (brDataArr.length > 1) {
            let t = 0;
            for (let j = 0; j < brDataArr.length; j++) {
              for (let k = 0; k < brDataArr.length; k++) {
                if (new Date(brDataArr[j].delivered_time).getTime() < new Date(brDataArr[k].delivered_time).getTime()) {
                  t = brDataArr[k];
                  brDataArr[k] = brDataArr[j];
                  brDataArr[j] = t;
                }
              }
            }
          }
          let countDateBr = 0;
          let dateCountBr = 0;
          // 获取前7天
          const day7br = [];
          const day15br = [];
          for (let i = 0; i < 7; i++) {
            day7br.push(this.getDay((-16 + i), '-'));
          }
          for (let i = 0; i < 15; i++) {
            day15br.push(this.getDay((-16 + i), '-'));
          }
          let cont7 = 0;
          let cont15 = 0;
          brDataArr.map(item => {
            day7br.map(list => {
              if (item.delivered_time === list) {
                cont7 = cont7 + item.count;
              }
            });
            day15br.map(date => {
              if (item.delivered_time === date) {
                cont15 = cont15 + item.count;
              }
            })
            countDateBr = countDateBr + (getDaysBetween(dateNew, item.delivered_time) * item.count);
            dateCountBr = dateCountBr + item.count;
          });
          const brProperCasting7 = (cont7 / brCountAll * 100).toFixed(1);
          const brNotProperlyCast7 = (100 - brProperCasting7).toFixed(1);
          this.brProportionIncome7.rows = [
            { '状态': '妥投', '数值': brProperCasting7 },
            { '状态': '未妥投', '数值': brNotProperlyCast7 },
          ]
          this.brAverageRate = (countDateBr / dateCountBr).toFixed(1);
          const brProperCasting15 = (cont15 / brCountAll * 100).toFixed(1);
          const brNotProperlyCast15 = (100 - brProperCasting15).toFixed(1);
          this.brProportionIncome15.rows = [
            { '状态': '妥投', '数值': brProperCasting15 },
            { '状态': '未妥投', '数值': brNotProperlyCast15 },
          ];
          /********* 智利 *************/
          const cl_delivery_count_statistical_info = JSON.parse(res.data.cl_delivery_count_statistical_info);
          const clDataArr = [];
          let clCountAll = 0;
          cl_delivery_count_statistical_info.map(item => {
            clCountAll = clCountAll + item.count;
            if (item.delivered_time) {
              clDataArr.push(item);
            }
          });
          // 按日期排序
          if (clDataArr.length > 1) {
            let t = 0;
            for (let j = 0; j < clDataArr.length; j++) {
              for (let k = 0; k < clDataArr.length; k++) {
                if (new Date(clDataArr[j].delivered_time).getTime() < new Date(clDataArr[k].delivered_time).getTime()) {
                  t = clDataArr[k];
                  clDataArr[k] = clDataArr[j];
                  clDataArr[j] = t;
                }
              }
            }
          }
          let countDateCl = 0;
          let dateCountCl = 0;
          let clCont7 = 0;
          let clCont15 = 0;
          clDataArr.map(item => {
            day7br.map(list => {
              if (item.delivered_time === list) {
                clCont7 = clCont7 + item.count;
              }
            });
            day15br.map(date => {
              if (item.delivered_time === date) {
                clCont15 = clCont15 + item.count;
              }
            });
            countDateCl = countDateCl + (getDaysBetween(dateNew, item.delivered_time) * item.count);
            dateCountCl = dateCountCl + item.count;
          });
          this.clAverageRate = (countDateCl / dateCountCl).toFixed(1);
          // 获取前7天
          const clProperCasting7 = (clCont7 / clCountAll * 100).toFixed(1);
          const clNotProperlyCast7 = (100 - clProperCasting7).toFixed(1);
          this.clProportionIncome7.rows = [
            { '状态': '妥投', '数值': clProperCasting7 },
            { '状态': '未妥投', '数值': clNotProperlyCast7 },
          ]
          // 获取前15天
          const clProperCasting15 = (clCont15 / clCountAll * 100).toFixed(1);
          const clNotProperlyCast15 = (100 - clProperCasting15).toFixed(1);
          this.clProportionIncome15.rows = [
            { '状态': '妥投', '数值': clProperCasting15 },
            { '状态': '未妥投', '数值': clNotProperlyCast15 },
          ];
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>

<style scoped>
  .titleClass{
    background: #00ACAF;
    color: #fff;
    font-weight: bold;
  }
</style>